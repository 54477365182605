<template>
  <div>
    <van-notice-bar
      left-icon="volume-o"
      text="停用专属后,取卡界面勾选使用专属项目时就不会使用此专属"
    />
    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-card
          :title="item.title2"
          v-for="(item, index) in list"
          :key="item.title"
          :price="item.price"
          :desc="item.desc"
        >
          <template #tags>
            <van-tag v-if="item.ctype == '实卡'" type="success">{{
              item.ctype
            }}</van-tag>
            <van-tag v-if="item.ctype == '虚卡'" plain type="success">{{
              item.ctype
            }}</van-tag>
            <van-tag v-if="item.ctype == '混合号段'" round type="success">{{
              item.ctype
            }}</van-tag>
            <van-tag plain type="danger">{{ item.address }}</van-tag>
          </template>
          <template #footer>
            <van-button
              type="info"
              size="mini"
              @click="onclick($event, item.title)"
              >{{ item.names }}</van-button
            >
            <van-button
              type="warning"
              size="mini"
              color="#7232dd"
              @click="onclick2($event, item.title)"
              v-if="item.old_price != item.price"
              >重新对接</van-button
            >
            <van-button type="danger" size="mini" v-if="item.state >= 2"
              >此项目被卡商停用</van-button
            >
            <van-button
              type="warning"
              size="mini"
              @click="onclick3($event, item.title, index)"
              v-if="item.state2 == 0 && item.state < 2"
              >停用专属</van-button
            >
            <van-button
              type="success"
              size="mini"
              @click="onclick3($event, item.title, index)"
              v-if="item.state2 == 1 && item.state < 2"
              >启用专属</van-button
            >
          </template>
        </van-card>

        <!-- <van-cell :title="item.title" v-for="(item,index) in list" :key="item.title">
          <van-button
            type="info"
            size="small"
            @click="onclick($event, item.title)"
            >{{ item.names }}</van-button
          >
          <van-button
            type="warning"
            size="small"
            color="#7232dd"
            @click="onclick2($event, item.title)"
            v-if="item.old_price != item.price"
            >重新对接</van-button
          >
          <van-button
            type="danger"
            size="small"
            v-if="item.state>=2"
            >此项目被卡商停用</van-button
          >
          <van-button
            type="warning"
            size="small"
            @click="onclick3($event, item.title,index)"
            v-if="item.state2==0 && item.state<2"
            >停用专属</van-button
          >
          <van-button
            type="success"
            size="small"
            @click="onclick3($event, item.title,index)"
            v-if="item.state2==1  && item.state<2"
            >启用专属</van-button
          >
        </van-cell> -->
      </van-list>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
    };
  },
  methods: {
    async onLoad() {
      try {
        let res = await this.$api.fn_user_exclusive_get();
        res = res.data;
        if (typeof res == "string") {
          res = res.replace("\\/g", "");
          res = eval("(" + res + ")");
        }
        res = res.data;
        let addition =
          (parseFloat(localStorage.getItem("agent_addition")) + 100) / 100;
        if (isNaN(addition)) {
          addition = 1;
        }
        res.forEach((element) => {
          if (element.在线 >= 0) {
            let name = "取消对接";
            this.list.push({
              title:
                element.name +
                "[" +
                element.id +
                "]  " +
                element.key_ +
                "----价格:" +
                parseFloat((element.price*addition).toFixed(4)) +
                "----可用:" +
                (element.在线 - element.已用) +
                "/" +
                element.在线 +
                "----类型:" +
                element.卡类型 +
                "----共享商UID:" +
                element.user_id,
              names: name,
              old_price: parseFloat(element.old_price),
              price: parseFloat((element.price*addition).toFixed(4)),
              state: parseInt(element.state),
              state2: parseInt(element.state2),
              title2: "[" + element.key_ + "]  " + element.name,
              desc:
                "可用/全部:" +
                (element.在线 - element.已用) +
                "/" +
                element.在线,
              ctype: element.卡类型,
              address: element.address,
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
      this.finished = true;
      // 加载状态结束
      this.loading = false;
    },
    async onclick(e, index) {
      if (e.target.innerText === "已经取消") {
        this.$toast("请不要重复点击");
        return;
      }
      let ret = await this.$api.fn_user_exclusive_pop(
        index.substring(index.indexOf("[") + 1, index.indexOf("]"))
      );
      ret = ret.data;
      if (ret.message === "ok") {
        this.$toast("取消成功");
        e.target.innerText = "已经取消";
      } else {
        this.$toast(ret.message);
        e.target.innerText = ret.message;
      }
    },
    async onclick2(e, index) {
      if (e.target.innerText === "对接成功") {
        this.$toast("请不要重复点击");
        return;
      }
      let ret = await this.$api.fn_user_exclusive_push(
        index.substring(index.indexOf("[") + 1, index.indexOf("]"))
      );
      ret = ret.data;
      if (ret.message === "ok") {
        this.$toast("对接成功");
        e.target.innerText = "对接成功";
        e.toElement.disabled = true;
      } else {
        this.$toast(ret.message);
        e.target.innerText = ret.message;
      }
    },
    async onclick3(e, index, f) {
      let state = 0;
      if (this.list[f].state2 == 1) {
        this.list[f].state2 = 0;
        state = 0;
      } else {
        this.list[f].state2 = 1;
        state = 1;
      }
      let ret = await this.$api.fn_user_exclusive_pop2(
        index.substring(index.indexOf("[") + 1, index.indexOf("]")),
        state
      );
      ret = ret.data;
      if (ret.message === "ok") {
        if (state == 0) {
          this.$toast("专属启用成功");
        } else {
          this.$toast("专属停用成功");
        }
      } else {
        this.$toast(ret.message);
        e.target.innerText = ret.message;
      }
    },
  },
};
</script>

<style scoped>
.serch {
  width: 100%;
  position: fixed;
  top: 5px;
  left: 0;
  z-index: 99;
}
.btn {
  width: 2rem;
  text-align: center;
  background: #f0eae7;
}
.list {
  margin-top: 00px;
}
</style>
